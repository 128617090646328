import React from "react";
import SearchResultPage from "../../../templates/search-results-template";
// import {
//     parseSearchUrl,
//     savedSearchParams,
//     propertyH1
// } from "../../../components/common/site/utils";

const SearchResultResiLettings = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    // const searchParams = parseSearchUrl("sales", fullpath);

    return (
        <React.Fragment>
            <SearchResultPage 
                locationname="/residential-property/to-rent/" 
                pcategorytype="residential" 
                ptype="lettings" 
                ptypetag="to-rent" 
                pstatustype="To Let" 
                fullpathone={fullpathone} 
                fullpathname={fullpath}
                {...props}
            />
        </React.Fragment>
    );
};

export default SearchResultResiLettings;
